import React, {Component} from 'react';
import {LIVE} from "../../config";

class Live extends Component {
    componentDidMount() {
        document.title = "EECF|基督教法国华人教会|Live"
    }

    render() {
        return (<div>
            <div className="row">
                <div className="col">
                    <h5>主日崇拜</h5>
                    <div id="worship-description">
                        <p><span id="worship-date">{LIVE.date}</span></p>
                        <p>主题: <span id="theme">{LIVE.theme}</span></p>
                        <p>经文: <span id="chapter">{LIVE.chapter}</span></p>
                        <p>讲员: <span id="speaker">{LIVE.speaker}</span></p>
                        <p>
                            <a className="ytb-link" href={"https://www.youtube.com/watch?v=" + LIVE.youtubeVideoCode}>
                                <img src="/img/yt_eecf_logo.png" alt="youtube eecf"/>
                            </a>
                        </p>
                        <a href={"https://www.youtube.com/watch?v=" + LIVE.youtubeVideoCode}
                           className="btn btn-primary btn-lg active ytb-link"
                           role="button"
                           aria-pressed="true">点击观看</a>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <a href="https://youtube.com/@eecf"
                       className="btn btn-outline-primary"
                       role="button"
                       aria-pressed="true">查看YouTube频道，回顾往期录像</a>
                    <p><a href="https://youtube.com/@eecf">https://youtube.com/@eecf</a></p>
                    <p>欢迎订阅</p>
                </div>
            </div>
        </div>);
    }
}

export default Live;