import React, {Component} from 'react';


class Header extends Component {
    render() {
        return (
            <div className="row mt-1 mb-5">
                <div className="col">
                    <h2>基督教法国华人教会</h2>
                    <h3>EECF</h3>
                </div>
            </div>
        );
    }
}

export default Header;