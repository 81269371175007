import React, {Component} from 'react';
import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import Live from "./pages/Live";
import Home from "./pages/Home";

class App extends Component {
    render() {
        return (
            <div className="container-fluid text-center">
                <Header/>
                <div className="row">
                    <Routes>
                        <Route path='/live' element={<Live/>}/>
                        <Route path='/' element={<Home/>}/>
                    </Routes>
                </div>
            </div>
        );
    }
}

export default App;