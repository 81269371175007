import React, {Component} from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                耶稣爱你
            </div>
        );
    }
}

export default Home;